const deviceContinuity = [
    {
        "openCircuitid": false,
        "name": "No"
    },
    {
        "openCircuitid": true,
        "name": "Yes"
    }
]

export default deviceContinuity;