import { Action } from "redux";

export enum ActionType {
    OPEN_DRAWER,
    CLOSE_DRAWER,
    OPEN_ALERT,
    CLOSE_ALERT,
    OPEN_SPINNER,
    CLOSE_SPINNER,

    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,

    SHOW_LOADER,
    HIDE_LOADER,

    GET_LOGIN_USER_DETAILS_SUCCESS,
    GET_LOGIN_USER_DETAILS_FAILURE,


    GET_CUSTOMERS,
    GET_DEVICES,
    GET_USERS,
    SET_FORGOT_PASSWORD,
    EDIT_CUSTOMER_DETAILS,
    EDIT_DEVICE_DETAILS,
    SET_CUSTOMER_SELECT_BOX_VALUE,
    EDIT_USER_DETAILS,
    GET_RECIPIENTS_USERS,
    GET_USER_SELECTED,
    OPEN_CONFIRM_BOX,
    CLOSE_CONFIRM_BOX,
    IS_VALID_TOKEN_REGISTRATION,
    GET_PU_DEVICE_DASHBOARD_DETAILS,
    GET_OU_DEVICE_DASHBOARD_DETAILS,
    GET_OU_CUSTOMER_DASHBOARD_DETAILS,
    GET_LOCATION_MARKERS,
    GET_DASHBOARD_NOTIFICATIONS,
    GET_ATTACHMENT_LIST,
    GET_SENSOR_READINGS,
    GET_SENSOR_READINGS_RESET,
    GET_SENSOR_DETAILS_BY_DEVICE_ID,
    GET_OWNERS,
    EDIT_OWNER_DETAILS,
    USER_PROFILE_DETAILS,
    SEARCH_SENSOR_READING,
    GET_NOTIFICATIONS_LIST,
    UPDATE_DEVICE_DATA,

    LOGIN_USER_DETAILS_SHOW_LOADER,
    LOGIN_USER_DETAILS_HIDE_LOADER,
    GET_DEVICES_DROP_DOWN_LIST,
    USER_LOGOUT,
    GET_PRICING_DETAILS,
}

export interface IAppAction extends Action<ActionType> {
    payload?: any;
}