import { RouteAccess } from '../AppConstants';
import LoginButton from '../components/Auth/LoginButton';
import DeviceGrid from '../pages/device/Devices';
import DeviceForm from '../pages/device/DeviceForm';
const routes = [
  {
    path: '/login',
    component: LoginButton,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/',
    component: LoginButton,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/edit-device-details/:id',
    component: DeviceForm,
    access: RouteAccess.Administrator,
    exact: true,
  },
 
  {
    path: '/devices',
    component: DeviceGrid,
    access: RouteAccess.Administrator,
    exact: true,
  },
  {
    path: '/device-register',
    component: DeviceForm,
    access: RouteAccess.Administrator,
    exact: true,
  },
  {
    path: '/devices',
    component: DeviceGrid,
    access: RouteAccess.Administrator,
    exact: true,
  },

];

export default routes;
