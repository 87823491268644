import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
const UnWiredThem = createTheme({
  palette: {
    mode: "light",
    primary: {
      light: "#4597db",
      main: "#5badf1",
      dark: "#005684",
      contrastText: "#fff",
    },
    secondary: {
      light: "#f55089",
      main: "#e16f96",
      dark: "#f55089",
      contrastText: "#fff",
    },
    error: {
      main: red.A400,
    },

    background: {
      default: "#f3f3f6",
    },
    
  },
  typography: {
    fontFamily: '"Yanone Kaffeesatz", Arial, Helvetica, sans-serif',
    fontSize: 16,
  },

});

export default UnWiredThem;
