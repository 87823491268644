import React, { useMemo } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";

import useResponsiveFontSize from "../responsive-font/useResponsiveFontSize";
import PropTypes from 'prop-types';
import { toast } from "react-toastify";
import { Button } from "@mui/material";


const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const CardDetailForm = (props: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }


    const result = await stripe.createToken(elements.getElement(CardNumberElement) as any);

    if (result.error) {
      // Show error to your customer.
      toast.error(result.error.message);
      console.log(result.error.message);
    } else {
      // Send the token to your server.
      // This function does not exist yet; we will define it in the next step.
      props.handleSubmitFunc(result.token);
    }

    // const paymentMethod = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: elements.getElement(CardNumberElement) as any
    // });
    // props.handleSubmitFunc(paymentMethod);
  };

  return (
    <form autoComplete="off">
      <label>
        Card Number
        <CardNumberElement
          options={options}
        />
      </label>
      <label>
        Expiration Date
        <CardExpiryElement
          options={options}
        />
      </label>
      <label>
        CVC
        <CardCvcElement
          options={options}
        />
      </label>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" onClick={(event: any) => handleSubmit(event)} disabled={!stripe} >
          Pay
        </Button>
      </div>

    </form >

  );
};

CardDetailForm.propTypes = {
  handleSubmitFunc: PropTypes.func.isRequired,
}
CardDetailForm.defaultProps = {
  handleSubmitFunc: () => { }
};

export default CardDetailForm;
