import { Button, Grid, Hidden, InputBase, Menu, MenuItem, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from '@mui/styles';
const styles = (theme: Theme) => createStyles({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
        marginLeft: 0,
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        border: '1px solid #ccc',
        [theme.breakpoints.between('lg', 'xl')]: {
            width: '46ch',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '35ch',
        },
        [theme.breakpoints.only('xs')]: {
            width: '17ch',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    inputRoot: {
        width: '50%',
        [theme.breakpoints.only('xs')]: {
            width: '80%',
        },
        color: 'inherit',
        border: '1px solid #ccc',
        paddingLeft: 8,
        height: '40px',
    },

    mobileView: {
        marginTop: '5px',
        [theme.breakpoints.between('sm', 'xl')]: {
            padding: 8
        },
    },
    mobileSearchBox: {
        [theme.breakpoints.only('xs')]: {
            paddingRight: '13px'
        },

    }

});
export const SearchBox = withStyles(styles)((props: any) => {
    const { classes, anchorEl, handleClose, handleClickListItem, filterMenuOptions,
        selectedFilterMenuIndex, handleMenuItemClick, searchBoxValue, handleSearchBox,
        onEnterKeyPress, selectedFilterMenu, handleCloseExportMenu, buttonAddFunction, buttonAddLabel,
        exportMenuOptions, handleClickExport, exportAnchorEl, selectedExportMenuIndex, handleDownloadMenuItemClick,
        gridLabel
    } = props;
    return (
        <Grid container={true} style={{ margin: '5px' }}>
            <Grid item={true} xs={12} sm={filterMenuOptions ? 1 : 4} style={{ paddingLeft: 8, display: 'flex', alignItems: 'center' }}>
                <Typography component={'h2'} variant="body1" style={{ fontWeight: 'bolder', fontSize: 18 }}>{gridLabel}</Typography>
            </Grid>
            {filterMenuOptions &&
                <Grid item={true} xs={12} sm={4} >
                    <Typography component={'div'} style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Button style={{ backgroundColor: '#f6f6f6', textAlign: "center", color: '#6d6c6c' }}
                            onClick={(event) => handleClickListItem(event)}>Filter By</Button>
                        <Menu
                            id="show-filter-menu"
                            anchorEl={anchorEl}
                            keepMounted={true}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            elevation={0}
                        >
                            {filterMenuOptions.map((option: any, index: number) => {
                                return (
                                    <MenuItem
                                        key={option.key}
                                        disabled={index === selectedFilterMenuIndex}
                                        selected={index === selectedFilterMenuIndex}
                                        onClick={(event: any) => handleMenuItemClick(event, index, option)}
                                    >
                                        {option.label}
                                    </MenuItem>
                                )
                            }
                            )}
                        </Menu>
                        <Hidden only={['sm', 'xs']}>
                            <Button color="primary" style={{ backgroundColor: '#f6f6f6', color: '#6d6c6c' }} disabled={true}>[{selectedFilterMenu}]</Button>
                        </Hidden>
                    </Typography>
                </Grid>
            }

            {filterMenuOptions && <Grid item={true} xs={12} sm={filterMenuOptions ? 7 : 8}>
                <div className={classes.search} >
                    <InputBase
                        placeholder="Search…"
                        name="searchBoxValue"
                        id="searchBoxValue"
                        value={searchBoxValue}
                        classes={{ root: classes.inputRoot, input: classes.inputInput, }}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={handleSearchBox}
                        onKeyPress={onEnterKeyPress}
                    />
                </div>
            </Grid>}

        </Grid>
    )
});