import { Badge, CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

interface IPlanDetails {
    price: number,
    type: string,
    isDefault: boolean,

}
PriceDetailCard.propTypes = {
    planDetails: PropTypes.object.isRequired,
    onSelectPayment: PropTypes.func,
}
PriceDetailCard.defaultProps = {
    planDetails: {},
    onSelectPayment: () => { }
};
export default function PriceDetailCard(props: any) {
    const { planDetails, onSelectPayment } = props;
    return (
                <div className={planDetails.isDefault ? 'box active' : 'box'}
                onClick={() => onSelectPayment()}>
                {planDetails.type === "Yearly" && <div className="ribbon-2">Best</div>}
                <div>
                    <Typography variant="h6" color="text.secondary">
                        Live Tracking
                    </Typography>
                    <div style={{display:'flex',flexDirection:'row',alignItems: 'baseline'}}>
                    <Typography variant="h4">
                        ${planDetails.price+'  ' || '--'}
                    </Typography>
                    <Typography variant="h6" color="text.secondary" sx={{fontSize:'20px'}}>
                            /{planDetails.type || '--'}
                    </Typography>
                    </div>
                
                </div>
            </div>
    );
}
