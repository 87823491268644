export const loginUserApi = {
    getLoginUserDetails: {
        url: 'users/user/me',
        method: 'get',
        baseURL: 'loginDetails',
        get apiEndPoint() {
            return this.url;
        },
    }
}


export const usersEndPoint = {
    putUpdateUserById: {
        userId: '',
        url: 'users/user/',
        method: 'put',
        baseURL: 'users',
        get apiEndPoint() {
            return this.url + this.userId;
        },
    },
}

export const authorize = {
    login: {
        url: 'auth/login',
        method: 'post',
        baseURL: 'auth',
        get apiEndPoint() {
            return this.url;
        },
    },
    logOut: {
        url: 'auth/logout',
        method: 'get',
        baseURL: 'user-new-micro',
        get apiEndPoint() {
            return this.url;
        },
    },


    registration: {
        url: 'users/setpassword',
        method: 'post',
        baseURL: 'registration',
        get apiEndPoint() {
            return this.url;
        },
    },
    verifyUserToken: {
        url: 'users/verifyToken?',
        method: 'get',
        baseURL: 'tokenVerify',
        parameterName: '',
        parameterValue: '',
        get apiEndPoint() {
            return this.url + 'verificationKey=' + this.parameterValue;
        },
    },
    forgotPassword: {
        url: 'users/forgotpassword?',
        method: 'get',
        baseURL: 'forgotpassword',
        parameterName: '',
        parameterValue: '',
        get apiEndPoint() {
            return this.url + 'userName=' + this.parameterValue;
        },
    }
}

export const loginUserDetails = {
    getUserDetailsByToken: {
        url: 'users/user/me',
        method: 'get',
        baseURL: 'users',
        get apiEndPoint() {
            return this.url;
        },
    },
    getUserDetailsByTokenId: {
        url: 'users/user/me',
        method: 'get',
        baseURL: 'user',
        get apiEndPoint() {
            return this.url;
        },
    },
}

export const customers = {
    getCustomersByRange: {
        url: 'customers/range?',
        method: 'get',
        baseURL: 'customers',
        start: 0,
        limit: 10,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: any) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: any) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        get apiEndPoint() {
            return this.url + ((this.parameterName ? ('parameterName=' + this.parameterName) : '') + (this.parameterValue ? ('&parameterValue=' + this.parameterValue) : '')) + (this.parameterName ? '&start=' : 'start=') + this.start + '&limit=' + this.limit;
        },
    },
    getCustomersByOwnerId: {
        id: '',
        url: 'owners/owner/',
        method: 'get',
        baseURL: 'customers',
        start: 0,
        limit: 10,
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        endUrl: '/customers/range?',
        get apiEndPoint() {
            return this.url + this.id + this.endUrl + 'start=' + this.start + '&limit=' + this.limit;
        },
    },
    getDashBoardDeviceCountDetails: {
        id: '',
        url: 'reports/owner/customer/device/count',
        method: 'get',
        baseURL: 'dashboard',
        endUrl: '?ownerId=',
        get apiEndPoint() {
            return this.url + (this.id ? this.endUrl + this.id : '');
        },
    },
    getDevicesByCustomerIdToShowTheMarkers: {
        customerId: '',
        url: '/customers/customer/',
        method: 'get',
        baseURL: 'customers',
        start: 0,
        limit: 50,
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        endUrl: '/devices?',
        get apiEndPoint() {
            return this.url + this.customerId + this.endUrl + 'start=' + this.start + '&limit=' + this.limit;
        },
    },
    putUpdateCustomerById: {
        customerId: '',
        url: 'customers/customer/',
        method: 'put',
        baseURL: 'customerSave',
        get apiEndPoint() {
            return this.url + this.customerId;
        },

    },
    postCustomer: {
        url: 'customers/customer/',
        method: 'post',
        baseURL: 'customerSave',
        get apiEndPoint() {
            return this.url;
        },

    }

}
export const devices = {
    postETAAddress: {
        url: 'etaAddress/create/',
        method: 'post',
        baseURL: 'devices',
        get apiEndPoint() {
            return this.url
        }
    },
    getDevicesByRangeUnassigned: {
        url: 'devices/unAssigned/range?',
        method: 'get',
        baseURL: 'devices',
        start: 0,
        limit: 10,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: any) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: any) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        get apiEndPoint() {
            return this.url + ((this.parameterName ? ('parameterName=' + this.parameterName) : '') + (this.parameterValue ? ('&parameterValue=' + this.parameterValue) : '')) + (this.parameterName ? '&start=' : 'start=') + this.start + '&limit=' + this.limit;
        },
    },
    getDevicesByRange: {
        url: 'devices/range?',
        method: 'get',
        baseURL: 'devices',
        start: 0,
        limit: 10,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: any) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: any) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        get apiEndPoint() {
            return this.url + ((this.parameterName ? ('parameterName=' + this.parameterName) : '') + (this.parameterValue ? ('&parameterValue=' + this.parameterValue) : '')) + (this.parameterName ? '&start=' : 'start=') + this.start + '&limit=' + this.limit;
        },
    },
    getDeviceById: {
        id: '',
        url: 'devices/',
        method: 'get',
        baseURL: 'devices',
        get apiEndPoint() {
            return this.url + this.id
        },
    },
    putSaveDeviceDetails: {
        deviceId: '',
        url: 'devices/device/',
        method: 'put',
        baseURL: 'device',
        get apiEndPoint() {
            return this.url + this.deviceId;
        },
    },
    postSaveDeviceDetails: {
        url: 'devices/device/',
        method: 'post',
        baseURL: 'device',
        get apiEndPoint() {
            return this.url;
        },
    },
    getPUActiveAndInActiveDeviceCout: {
        id: '',
        url: 'reports/pu/device/activeinactive/count',
        method: 'get',
        baseURL: 'dashboard',
        endUrl: '?ownerId=',
        get apiEndPoint() {
            return this.url + (this.id ? this.endUrl + this.id : '');
        },
    },
    getOUActiveAndInActiveDeviceCout: {
        id: '',
        url: 'reports/owner/device/activeinactive/count',
        method: 'get',
        baseURL: 'dashboard',
        endUrl: '?ownerId=',
        get apiEndPoint() {
            return this.url + (this.id ? this.endUrl + this.id : '');
        },
    },
    getPUDashBoradAlerts: {
        duration: '',
        url: 'notification/range?',
        method: 'get',
        baseURL: 'dashboard',
        start: 0,
        limit: 10,
        endUrl: '?duration=',
        get apiEndPoint() {
            return this.url + this.endUrl + this.duration + '&start=' + this.start + '&limit=' + this.limit;
        },
    },
    getLocationsByDeviceId: {
        id: '',
        url: 'devices/device/',
        method: 'get',
        baseURL: 'deviceLocations',
        endUrl: '/locations/readings/latest',
        get apiEndPoint() {
            return this.url + this.id + this.endUrl;
        },
    }
}
export const users = {
    putSaveUserByCustomerId: {
        customerId: '',
        url: 'customers/customer/',
        method: 'put',
        baseURL: 'users',
        endUrl: '/users/add',
        get apiEndPoint() {
            return this.url + this.customerId + this.endUrl;
        },
    },
    putUpdateUserById: {
        userId: '',
        url: 'users/user/',
        method: 'put',
        baseURL: 'users',
        get apiEndPoint() {
            return this.url + this.userId;
        },
    },
    getUserById: {
        userId: '',
        url: 'users/',
        method: 'get',
        baseURL: 'users',
        get apiEndPoint() {
            return this.url + this.userId;
        },
    },
    getUsersByRangeAsPU: {
        id: '',
        url: 'customers/customer/',
        method: 'get',
        baseURL: 'users',
        start: 0,
        limit: 10,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: any) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: any) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        endUrl: '/users/range?',
        get apiEndPoint() {
            return this.url + this.id + this.endUrl + ((this.parameterName ? ('parameterName=' + this.parameterName) : '') + (this.parameterValue ? ('&parameterValue=' + this.parameterValue) : '')) + (this.parameterName ? '&start=' : 'start=') + this.start + '&limit=' + this.limit;
        },
    },
    getUsersByRangeAsOwnerOrAU: {
        url: 'owners/customers/users?',
        method: 'get',
        baseURL: 'owners',
        start: 0,
        limit: 10,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: any) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: any) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        get apiEndPoint() {
            return this.url + ((this.parameterName ? ('parameterName=' + this.parameterName) : '') + (this.parameterValue ? ('&parameterValue=' + this.parameterValue) : '')) + (this.parameterName ? '&start=' : 'start=') + this.start + '&limit=' + this.limit;
        },
    },
    getUsersRangeByDeviceId: {
        id: '',
        url: 'devices/device/',
        method: 'get',
        baseURL: 'users',
        start: 0,
        limit: 10,
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        endUrl: '/users/range?',
        get apiEndPoint() {
            return this.url + this.id + this.endUrl + 'start=' + this.start + '&limit=' + this.limit;
        },
    },
    putAddUserByDeviceId: {
        id: '',
        url: 'devices/device/',
        method: 'put',
        baseURL: 'users',
        endUrl: '/users/add',
        get apiEndPoint() {
            return this.url + this.id + this.endUrl;
        },
    },
    postRegisterUser: {
        url: 'users/user/',
        method: 'post',
        baseURL: 'users',
        get apiEndPoint() {
            return this.url;
        },
    },
    sendInviteToUserMail: {
        userName: '',
        url: 'users/registrationlink?userName=',
        method: 'get',
        baseURL: 'users',
        set setUserName(userName: string) {
            this.userName = userName;
        },
        get apiEndPoint() {
            return this.url + this.userName;
        },
    }
}
export const sensorReadings = {
    getSensorReadingsByRange: {
        id: '',
        url: 'sensorreading/range?',
        method: 'get',
        baseURL: 'sensorreading',
        start: 0,
        limit: 10,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: any) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: any) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        get apiEndPoint() {
            return this.url + 'start=' + this.start + '&limit=' + this.limit + (this.parameterName ? ('&parameterName=' + this.parameterName + '&parameterValue=' + this.parameterValue) : '');
        },
    },
    getSensorDetailsByDeviceId: {
        id: '',
        url: 'devices/device/',
        method: 'get',
        baseURL: 'sensor',
        start: 0,
        limit: 20,
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        endUrl: '/sensors/range?',
        get apiEndPoint() {
            return this.url + this.id + this.endUrl + 'start=' + this.start + '&limit=' + this.limit;
        },
    },
    postSensorConfigurationsSave: {
        url: 'sensorconfigurations/',
        method: 'post',
        baseURL: 'sensor',
        get apiEndPoint() {
            return this.url;
        },
    },
    getAllLocationMarkers: {
        id: '',
        url: 'locationreading/positions/',
        method: 'get',
        baseURL: 'locationmarkers',
        start: 0,
        limit: 20,
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        endUrl: '?',
        get apiEndPoint() {
            return this.url + this.id + this.endUrl + 'start=' + this.start + '&limit=' + this.limit;
        },
    }
}

export const shipment = {
    getShipmentsByRange: {
        url: 'shipments/shipment/range',
        method: 'get',
        baseURL: 'order',
        start: 0,
        limit: 10,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: any) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: any) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        get apiEndPoint() {
            return this.url + 'start=' + this.start + '&limit=' + this.limit + ((this.parameterName && this.parameterValue) && ('&parameterName=' + this.parameterName) + '&parameterValue=' + this.parameterValue);
        },
    },
    postSaveORUpdateShipment: {
        url: 'shipments/shipment/',
        method: 'post',
        baseURL: 'shipment',
        get apiEndPoint() {
            return this.url;
        },
    }
}

export const notification = {
    getNotificationByRange: {
        url: 'notification/range?',
        method: 'get',
        baseURL: 'notification',
        start: 0,
        limit: 10,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: any) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: any) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        get apiEndPoint() {
            return this.url + ((this.parameterName ? ('parameterName=' + this.parameterName) : '') + (this.parameterValue ? ('&parameterValue=' + this.parameterValue) : '')) + (this.parameterName ? '&start=' : 'start=') + this.start + '&limit=' + this.limit;
        },
    },
    postSaveORUpdateOrders: {
        url: 'orders/order/',
        method: 'post',
        baseURL: 'order',
        get apiEndPoint() {
            return this.url;
        },
    }
}

export const orders = {
    getOrdersByRange: {
        url: 'orders/order/range',
        method: 'get',
        baseURL: 'order',
        start: 0,
        limit: 10,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: any) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: any) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        get apiEndPoint() {
            return this.url + ((this.parameterName ? ('parameterName=' + this.parameterName) : '') + (this.parameterValue ? ('&parameterValue=' + this.parameterValue) : '')) + (this.parameterName ? '&start=' : 'start=') + this.start + '&limit=' + this.limit;
        },
    },
    postSaveORUpdateOrders: {
        url: 'orders/order/',
        method: 'post',
        baseURL: 'order',
        get apiEndPoint() {
            return this.url;
        },
    }
}


export const owners = {
    getOwnerByRange: {
        url: 'owners/range?',
        method: 'get',
        baseURL: 'owner',
        start: 0,
        limit: 10,
        parameterName: '',
        parameterValue: '',
        set setParameterName(parameterName: any) {
            this.parameterName = parameterName;
        },
        set setParameterValue(parameterValue: any) {
            this.parameterValue = parameterValue;
        },
        set setStart(start: number) {
            this.start = start;
        },
        set setLimit(limit: number) {
            this.limit = limit;
        },
        get apiEndPoint() {
            return this.url + ((this.parameterName ? ('parameterName=' + this.parameterName) : '') + (this.parameterValue ? ('&parameterValue=' + this.parameterValue) : '')) + (this.parameterName ? '&start=' : 'start=') + this.start + '&limit=' + this.limit;
        },
    },
    getOwnerById: {
        appOwnerId: '',
        url: 'owners/owner/',
        method: 'get',
        baseURL: 'owner',
        get apiEndPoint() {
            return this.url + this.appOwnerId;
        },
    },
    postSaveOwner: {
        url: 'owners/owner/',
        method: 'post',
        baseURL: 'owner',
        get apiEndPoint() {
            return this.url;
        },
    },
    putSaveOwner: {
        appOwnerId: '',
        url: 'owners/owner/',
        method: 'put',
        baseURL: 'owner',
        get apiEndPoint() {
            return this.url + this.appOwnerId;
        },
    }
}

export const payment = {
    getPricingDetails: {
        url: 'payments/pricing',
        method: 'get',
        baseURL: 'payment',
        get apiEndPoint() {
            return this.url;
        },
    },

    postPaymentCharge: {
        url: 'payments/payment',
        method: 'post',
        baseURL: 'payment',
        get apiEndPoint() {
            return this.url;
        },
    },
    post: {
        url: '',
        method: 'post',
        baseURL: '',
        get apiEndPoint() {
            return this.url;
        },
    },
    put: {
        url: '',
        method: 'put',
        baseURL: '',
        get apiEndPoint() {
            return this.url;
        },
    }
}