import { Auth0Provider, withAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import history from '../@history';
import './App.css';
import AppContext from './AppContext';
import Layout from './layout/Layout';
import routes from './route-configs/routes.config';
import store from './store';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');
function App() {
  return (
    <React.StrictMode>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN ? process.env.REACT_APP_AUTH0_DOMAIN : ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ? process.env.REACT_APP_AUTH0_CLIENT_ID : ''}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        redirectUri={window.location.origin}
      // useRefreshTokens={true}
      // cacheLocation={"localstorage"}
      >
        <AppContext.Provider value={{ routes }}>
          <Provider store={store}>
            <BrowserRouter >
              <Elements stripe={stripePromise}>
                <Layout history={history} />
              </Elements>
            </BrowserRouter>
          </Provider>
        </AppContext.Provider>

      </Auth0Provider>
    </React.StrictMode>
  );
}

export default withAuth0(App);
