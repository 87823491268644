import { FormControl, InputLabel, Input } from '@mui/material';
import { usePlacesWidget } from "react-google-autocomplete";
import PropTypes from 'prop-types';

const CustomGoogleAutocomplete = (props: any) => {
    const { placeholder, id, onPlaceSelected, isRequired, value, onBlur, onChange, name } = props;
    const { ref: materialRef } = usePlacesWidget({
        apiKey:process.env.REACT_APP_GOOGLE_MAP_KEY,
        onPlaceSelected: (place) => onPlaceSelected(place),
        inputAutocompleteValue: "country",
        options: {
            componentRestrictions: { country: ['us', 'ca'] },
            fields: ["address_components", "geometry"],
            types: ["address"],
        },
    });
    return (<FormControl required={isRequired} fullWidth={true} >
        <InputLabel shrink={true} htmlFor={id}>{placeholder}</InputLabel>
        <Input fullWidth name={name} color="primary" id={id} value={value}
            onBlur={onBlur} onChange={onChange} inputRef={materialRef} />
    </FormControl>)
};

CustomGoogleAutocomplete.propTypes = {
    placeholder: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onPlaceSelected: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    value: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    autoComplete: PropTypes.string,
    className: PropTypes.object
}

CustomGoogleAutocomplete.defaultProps = {
    placeholder: '',
    name: '',
    onPlaceSelected: () => { },
    onChange: () => { },
    onBlur: () => { },
    value: '',
    isRequired: false,
    autoComplete: 'off',
    className: {}
}

export default CustomGoogleAutocomplete;