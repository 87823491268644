import './DeviceDetail.css'
import { Button, Card, Grid, Paper, Theme, Typography, Icon, IconButton } from "@mui/material";
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import IDeviceView from '../../models/device/IDeviceView';
import { getDateFormateWithTime } from "../../utilities/DateUtils";
import EditIcon from '@mui/icons-material/Edit';
interface IEnhancedDealCardProps {
    classes?: any;
    deviceDetail: IDeviceView;
    onEdit: () => void;
    openMapWindow: () => any;
    index: number;
}
const DeviceDetailCard = (props: IEnhancedDealCardProps) => {

    const dateOption: any = { year: 'numeric', month: 'short', day: '2-digit' };
    const timeOption: any = { hour: '2-digit', minute: '2-digit' };
    const { classes, deviceDetail, onEdit, openMapWindow, index } = props;
    const { nickName, name, modemProgramVersion, lastMessageSent, model,
        swVersion, updateDateTime, lastReading, sensitivity, communicationInterval } = deviceDetail;
    return (
        // <div className="container">
            <div className="card">
                <div className="action">
                <IconButton style={{ backgroundColor: '#c2e3f1' }}>
                    < EditIcon onClick={() => onEdit()}/>
                </IconButton>
                </div>

                <div className="imgBx">
                    <img
                        src="/images/ctd.png" alt="device" />
                </div>
                <div className="contentBx">
                    <h3>{nickName}</h3>
                    <h4 style={{ margin: '10px' }}>{model}</h4>

                    <div className="color">
                        <Typography className={classes.descriptionText}>
                            Alert Sensitivity:
                        </Typography>
                        <Typography className={classes.titleText}>
                            {sensitivity || '--'}
                        </Typography>
                    </div>

                    <div className="color">
                        <Typography className={classes.descriptionText} >
                            Serial #:
                        </Typography>
                        <Typography className={classes.titleText}>
                            {name || '--'}
                        </Typography>
                    </div>

                    <div className="color">
                        <Typography className={classes.descriptionText}>
                            Report Frequency:
                        </Typography>
                        <Typography className={classes.titleText}>
                            {communicationInterval ? (communicationInterval / 60) + 'hr' : '--'}
                        </Typography>
                    </div>
                    <div className="size">
                        <Typography className={classes.descriptionText}>
                            Last  Reading :
                        </Typography>
                        <Typography className={classes.titleText}>
                            {new Date(lastReading).toLocaleDateString('en-US', dateOption) + ' ' + new Date(lastReading).toLocaleTimeString('en-US', timeOption)}
                        </Typography>
                    </div>

                    <Button variant="contained" size="small"
                        style={{ marginBottom: 20, marginTop: 10 }}
                        onClick={() => openMapWindow()}> <Icon>location_on</Icon>
                        Find me </Button>
                </div>
            </div>
       // </div>
    );
}

DeviceDetailCard.propTypes = {
    onClick: PropTypes.func.isRequired,
    deviceDetail: PropTypes.object.isRequired,
    className: PropTypes.object
}

DeviceDetailCard.defaultProps = {
    onClick: () => { },
    className: {},
    deviceDetail: {}
}


const styles = (theme: Theme) => ({
  
    titleTitleText: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: '13px !important',
        [theme.breakpoints.down('md')]: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        [theme.breakpoints.down('sm')]: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 10,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
    },
    descriptionText: {
        fontSize: '13px !important',
        fontWeight: '700 !important',
        display: 'flex',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
    },
    titleText: {
        fontSize: '12px !important',
        fontWeight: 100,
        display: 'flex',
        justifyContent: 'start',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('md')]: {
            '-webkit-line-clamp': 3,
        },
    },
   
    description: {
        fontSize: "12px",
        color: 'gray',
        margin: '0 5px 5px',
        height: 82,
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('md')]: {
            marginBottom: 20,
        },
    },
    card:{
        position: 'relative',
        width: '320px',
        height: '450px',
        background: '#c2e3f1',
        borderRadius: '20px',
        overflow: 'hidden',
        [theme.breakpoints.between('xs', 'sm')]: {
            height: '400px',
            width: '250px'
          },
    }

});
export default withStyles(styles as any)(DeviceDetailCard as any) as any;

