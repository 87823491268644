import { combineReducers } from 'redux';
import { auth } from './Auth/Auth.Reducer'
import { common } from './Common.Reducers';
import devicesReducer from './DevicesReducer';
import customersReducer from './CustomerReducer';
import sensorReadingsReducer from "./SensorReadingsReducer";
const rootReducer = combineReducers({
    common,
    auth: auth,
    device: devicesReducer,
    customer: customersReducer,
    sensorReading: sensorReadingsReducer,

});
export default rootReducer;
