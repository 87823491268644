import axios from "axios";
import { toast } from "react-toastify";
export const axiosInstance: any = axios.create({
    headers: {
        "Accept": '*/*',
    },
});

export const logoutSessionExpire = async (count) => {
    const makeCountAsNumber = count ? +count : count;
    sessionStorage.setItem("session", makeCountAsNumber + 1);
    axiosInstance.defaults.headers.common['Authorization'] = ''
}
