import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import {
    Box,
    Card,
    CardContent,
    Checkbox, Container, Dialog, FormControl, FormControlLabel, Grid, Input, InputLabel, MenuItem, Select, StepConnector, stepConnectorClasses, Table, TableBody, TableCell, TableHead, TablePagination, TableRow
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, withStyles } from '@mui/styles';
import React from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from "react-router-dom";
import { toast } from 'react-toastify';
import { bindActionCreators, Dispatch } from 'redux';
import SimpleReactValidator from 'simple-react-validator';
import PriceDetailCard from '../../components/card/PriceDetailCard';
import { DialogContent, DialogTitle } from '../../components/dialog/DialogTitle';
import CustomGoogleAutocomplete from '../../components/google/CustomGoogleAutocomplete';
import { PhoneNumberMaskInput } from '../../components/mask-input/PhoneNumberMaskInput';
import CardDetailForm from '../../components/strip/CardDetailForm';
import deviceContinuity from '../../data/common-data/DeviceContinuity';
import IOpenCircuitModel from '../../models/common/OpenCircuitModel';
import IDevice from '../../models/device/IDeviceView';
import IRecipient from '../../models/device/IRecipient';
import IUser from '../../models/user/IUser';
import * as DeviceAction from '../../store/actions/Devices.Action';
import * as CommonActions from '../../store/actions/Common.Action'
const classNames = require('classnames');

interface IPriceDetails {
    price: number,
    type: string,
    isDefault: boolean

}
interface IPageProps extends RouteComponentProps<{}> {
    classes?: any,
    history: any;
    editDeviceDetail: IDevice,
    match: any;
    getDeviceById: (deviceId: string) => any;
    resetEditDeviceDetails: () => void;
    resetDeviceGrid: () => void;
    putUpdateDeviceDetailsByDeviceId: (deviceId: string, body: string) => any;
    postSaveDeviceDetails: (body: string) => any;
    getDevicesByRangeSearch: (paramName: string | null | undefined, paramValue: string | null | undefined) => any
    getPricingDetails: () => void;
    postMakePayment: (body: string) => any;
    deviceAvailableList: IDevice[],
    user: IUser,
    pricingDetails: any[]
}

interface IPageState extends IDevice {
    activeStep: number;
    activeStepName:string;
    formSteps: string[];
    customerId?: string
    isUsersRoleOUorAU: any,
    openCircuitid?: string;
    isEditDeviceRecord: boolean;
    openModel: boolean;
    recipientDetails: IRecipient;
    isEditRecipient: boolean,
    pageNo: number,
    rowsPerPage: number,
    pageStartCount: number,
    editIndex: any;
    open: boolean,
    deviceName?: string,
    showDeviceError?: boolean,
    loading: boolean,
    availabilityOptions: any[],
    communicationIntervalOption?: any;
    pricingDetails?: any[];
    selectedPricingDetails?: any,
    paymentSuccess: boolean,
    staticDeviceName: string,
    isValid: boolean;
}
class DeviceForm extends React.Component<IPageProps, IPageState> {
    public validators: SimpleReactValidator = new SimpleReactValidator();
    public validator: SimpleReactValidator = new SimpleReactValidator();;
    public isUsersRoleOUorAU: any;
    public myTimeout?: any;
    public stepFormAvaliablity: any = ['Device Information', 'Device Address', "Subscription", 'Recipients']
    constructor(props: IPageProps) {
        super(props);
        this.state = {
            open: false,
            activeStep: 0,
            activeStepName:'Device Information',
            formSteps: ['Device Information', 'Device Address', "Subscription", 'Recipients'],
            isUsersRoleOUorAU: false,
            isEditDeviceRecord: false,
            recipientList: [],
            openModel: false,
            recipientDetails: {

            },
            isEditRecipient: false,
            pageNo: 0,
            rowsPerPage: 5,
            pageStartCount: 10,
            editIndex: null,
            showDeviceError: false,
            loading: false,
            paymentSuccess: false,
            deviceName: '135E',
            staticDeviceName: '135E',
            isValid: true,
            sensitivity: 5,
            communicationInterval: 1440,
            availabilityOptions: [{ value: 60, label: '1hr' }, { value: 240, label: '4hr' }, { value: 480, label: '8hr' }, { value: 1440, label: '24hr' }]
        }
    }

    public componentWillUnmount(): void {
        this.props.resetDeviceGrid();
        this.props.resetEditDeviceDetails();
    }

    public componentDidMount() {
        const pathname = this.props.match.path;
        if (pathname === "/edit-device-details/:id") {
            this.setState({ isEditDeviceRecord: true })
        }
        else {
            this.setState({ isEditDeviceRecord: false })
        }

        this.setState({ pricingDetails: this.props.pricingDetails })

    }
    private setFormValidation = () => {
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            messages: {
                integer: "Please enter only number"
            }
        });
        this.validators = this.state.formSteps.map((list: any, index: any) => {
            return {
                validator: new SimpleReactValidator({
                    autoForceUpdate: this,
                    messages: {
                        integer: "Please enter only number"
                    }
                })
            }
        }) as any

        // this.validator = new SimpleReactValidator({
        //     autoForceUpdate: this, messages: {
        //         integer: "Please enter only number"
        //     }
        // });
        // this.stepFormAvaliablity = ['Device Information', 'Device Address', "Subscription", 'Recipients'];
        // this.validators = this.stepFormAvaliablity.map((list: any, index: any) => {
        //     return {
        //         validator: new SimpleReactValidator({
        //             autoForceUpdate: this,
        //             messages: {
        //                 integer: "Please enter only number"
        //             }
        //         })
        //     }
        // })
    }
    public async componentWillMount() {
        this.setFormValidation();
        this.props.getDevicesByRangeSearch(null, null);
        const deviceId = this.props.match.params.id;
        const pathname = this.props.match.path;
        if (pathname === "/edit-device-details/:id") {
            if (deviceId) {
                await this.props.getDeviceById(deviceId).then((response: any) => {
                    if (response.status.success === "FAILED") {
                        toast.error(response.status.message);
                        this.props.history.push('/devices');
                    }

                }).catch((error: any) => {
                    toast("device not found", { type: 'error' })
                    this.props.history.push('/devices');
                });

            }
        }
        this.props.getPricingDetails();
    }
    public componentDidUpdate(prevProps: IPageProps, prevState: IPageState) {
        if (this.props.match?.params?.id && prevProps?.editDeviceDetail) {
            if (prevProps.editDeviceDetail.deviceId !== prevState.deviceId) {
                if (!prevState.deviceId) {
                    const { customerId, name } = prevProps.editDeviceDetail.customer || {};
                    if (customerId) {
                        this.setState({ customerId, customerName: name });
                    }
                    const { payment } = prevProps.editDeviceDetail || {};
                    if (payment && payment.paymentDate) {
                        this.setState({ formSteps: ['Device Information', 'Device Address', 'Recipients'],paymentSuccess:true });
                    } else {
                        this.setState({ formSteps: ['Device Information', "Subscription", 'Device Address', 'Recipients'] });
                    }
                    this.setFormValidation();

                    this.setState({
                        ...prevProps.editDeviceDetail,
                        deviceName: prevProps.editDeviceDetail?.name,
                        openCircuitid: (this.props.editDeviceDetail && prevProps.editDeviceDetail.openCircuit) ? 'Yes' : 'NO',
                        customMessagePowerOff: prevProps.editDeviceDetail.customMessagePowerOff,
                        customMessagePowerOn: prevProps.editDeviceDetail.customMessagePowerOn,
                        isEditDeviceRecord: (Object.keys(prevProps.editDeviceDetail).length > 0)
                    });
                    if (prevProps.editDeviceDetail?.communicationInterval) {
                        this.setState({
                            sensitivity: prevProps.editDeviceDetail?.sensitivity,
                            communicationInterval: prevProps.editDeviceDetail.communicationInterval,
                            communicationIntervalOption: { value: prevProps.editDeviceDetail.communicationInterval, label: (prevProps.editDeviceDetail.communicationInterval / 60) + 'hr' },
                        });
                    }
                    if (prevProps.editDeviceDetail.recipientList && prevProps.editDeviceDetail.recipientList.length > 0) {
                        const recipientListUpdate = prevProps.editDeviceDetail.recipientList;
                        recipientListUpdate.forEach((eachRecipient) => {
                            eachRecipient.isChecked = true;
                        })
                    }
                }

            }
        }

        if (prevProps.pricingDetails?.length !== prevState.pricingDetails?.length) {
            this.setState({ pricingDetails: this.props.pricingDetails })
        }
    }
    private handleNext = (index: number) => {
        if (this.validators[index].validator.allValid()) {
            //&& index == 0 && !(this.state.deviceName !== this.state.name)
            this.setState({ activeStep: this.state.activeStep as unknown as number + 1,activeStepName:this.state.formSteps[ this.state.activeStep as unknown as number + 1] });
        } else {
            this.validators[index].validator.showMessages();
            // this.forceUpdate();
        }
    };

    private handleBack = () => {
        this.setState({ activeStep: this.state.activeStep as unknown as number - 1,activeStepName:this.state.formSteps[ this.state.activeStep as unknown as number - 1] });
    };
    private handleDeviceGrid = () => {
        this.props.history.push('/devices')
    };
    private onhandleChangeForm = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }
    private handleChangeCustomer = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value as number
        });
    }
    private handleChangeUserRecipient = (event: any, index: number) => {
        const value = event.target.checked;
        const name = event.target.name;
        const getAllRecipients = this.state.recipientList;
        if (name === "textNotification") {
            getAllRecipients[index].textNotification = value
        } else if (name === 'phoneNotification') {
            getAllRecipients[index].phoneNotification = value
        } else if (name === "emailNotification") {
            getAllRecipients[index].emailNotification = value
        } else {
            getAllRecipients[index].isChecked = value
        }
        this.setState({ recipientList: getAllRecipients })
    }
    private handleChangeOpenCircuit = (event: any) => {
        const value = event.target.value;
        const foundopenCircuit = deviceContinuity.find((element: IOpenCircuitModel) => {
            return element.name === value;
        });
        if (foundopenCircuit) {
            this.setState({ openCircuit: foundopenCircuit.openCircuitid })
        } else {
            this.setState({ openCircuit: false })
        }
        this.setState({
            openCircuitid: value
        });

    }
    private handleInterval = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value as string
        });
    }


    private saveOrUpdateDeviceDetails = async (finishIndex: number) => {
        if (this.validators[finishIndex].validator.allValid()) {
            let deviceDetailsCopy: any = Object.assign({}, this.state);
            const deviceDetails = {
                deviceId: deviceDetailsCopy.deviceId,
                nickName: deviceDetailsCopy.nickName,
                recipientList: deviceDetailsCopy.recipientList,
                address: deviceDetailsCopy.address,
                address2: deviceDetailsCopy.address2,
                state: deviceDetailsCopy.state,
                city: deviceDetailsCopy.city,
                country: deviceDetailsCopy.country,
                postalCode: deviceDetailsCopy.postalCode,
                customer: deviceDetailsCopy.customer,
                appOwner: deviceDetailsCopy.appOwner,
                communicationInterval: +deviceDetailsCopy.communicationInterval,
                sensitivity: +deviceDetailsCopy.sensitivity
            }
            if (deviceDetails.deviceId) {
                await this.props.putUpdateDeviceDetailsByDeviceId(deviceDetails.deviceId, JSON.stringify(deviceDetails)).then((response) => {
                    if (response.status.success === "SUCCESS") {
                        toast.success(response.status.message)
                        this.props.history.push('/devices')
                    }
                    else {
                        toast.error(response.status.message)
                    }
                });
            }
        } else {
            this.validators[finishIndex].validator.showMessages();
        }
    }
    fillInAddress = (place) => {
        let address1 = "";
        let postcode = "";
        for (const component of place.address_components) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];
            switch (componentType) {
                case "street_number": {
                    address1 = `${component.long_name} ${address1}`;
                    break;
                }
                case "route": {
                    address1 += component.short_name;
                    break;
                }
                case "postal_code": {
                    postcode = `${component.long_name}${postcode}`;
                    this.setState({ postalCode: postcode })
                    break;
                }
                case "postal_code_suffix": {
                    if (!postcode) {
                        this.setState({ postalCode: component.long_name })
                    }
                    break;
                }
                case "locality":
                    this.setState({ city: component.long_name })
                    break;

                case "administrative_area_level_1": {
                    this.setState({ state: component.short_name })
                    break;
                }
                case "country":
                    this.setState({ country: component.long_name })
                    break;
            }
        }
        this.setState({ address: address1, })
    }

    private handleOpenModel = () => {
        this.setState({ openModel: true, recipientDetails: {} })
    }

    private handleCloseModel = () => {
        this.setState({ openModel: false })
    }
    private onhandleChangeRecipientForm = (event: any) => {
        const value = event.target.value;
        const name = event.target.name;
        const recipientDetails = this.state.recipientDetails;
        recipientDetails[name] = value;
        this.setState({
            recipientDetails: recipientDetails
        });
    }
    private onhandleChangeFormCheckBox = (event: any) => {
        const value = event.target.checked;
        const name = event.target.name;
        const recipientDetails = this.state.recipientDetails;
        recipientDetails[name] = value;
        this.setState({
            recipientDetails: recipientDetails
        });
    }

    private handleSubmitRecipientDetails = async () => {
        if (this.validator.allValid()) {
            const recipientList: any[] = this.state.recipientList ? this.state.recipientList : [];
            const mobileNumberRemoveMask = this.state.recipientDetails?.mobileNumber?.replaceAll('(', '')?.replaceAll(')', '')?.replaceAll('-', '')?.replaceAll(' ', '');
            const landlineNumberRemoveMask = this.state.recipientDetails?.landlineNumber?.replaceAll('(', '')?.replaceAll(')', '')?.replaceAll('-', '')?.replaceAll(' ', '');
            this.state.recipientDetails.mobileNumber = mobileNumberRemoveMask;
            this.state.recipientDetails.landlineNumber = landlineNumberRemoveMask;
            if (this.state.isEditRecipient && this.state.editIndex >= 0) {
                recipientList[this.state.editIndex] = this.state.recipientDetails;
            } else {
                recipientList.push(this.state.recipientDetails);
            }
            this.setState({ recipientList: recipientList, openModel: false, recipientDetails: {}, isEditRecipient: false, })

        } else {
            this.validator.showMessages();
        }
    }
    private handleChangeTableRowsPerPage = async (event: any) => {
        this.setState({ rowsPerPage: event.target.value, pageStartCount: event.target.value, pageNo: 0 });
    };
    private editRecord = (data: any, index: number) => {
        this.setState({ recipientDetails: { ...data }, editIndex: index, isEditRecipient: true, openModel: true, })

    }
    private deleteRecord = (data: any, index: number) => {
        const recipientList: any[] = this.state.recipientList;
        if (index > -1) {
            recipientList.splice(index, 1); // 2nd parameter means remove one item only
        }
        this.setState({ recipientList: recipientList })

    }

    private getSearchList = (searchValue) => {
        this.props.getDevicesByRangeSearch('name', searchValue);
    }

    private handleChangePage = (event: any, page: number) => {
        console.log('page', page)
    };

    private handleSubmitPayment = (tokenObject: any) => {

        if (tokenObject) {
            const { selectedPricingDetails, pricingDetails } = this.state;
            const payloadPayment = {
                device: { deviceId: this.state.deviceId },
                pricing: selectedPricingDetails ? selectedPricingDetails : pricingDetails?.find(details => details.isDefault),
                paymentToken: tokenObject.id,
                user: { userId: this.props.user?.userId }
            }
            this.props.postMakePayment(JSON.stringify(payloadPayment)).then((response) => {
                if (response.status.success === "SUCCESS") {
                    toast.success(response.status.message);
                    this.setState({ activeStep: this.state.activeStep as unknown as number + 1, paymentSuccess: true ,activeStepName:this.state.formSteps[ this.state.activeStep as unknown as number + 1] });
                }
                else {
                    toast.error(response.status.message)
                }
            });
        } else {
            toast.error("while submit the payment something went worng!!")
        }

    }
    private handleSelectPricingDetails = (pricingDetails, index) => {
        const pricingDetailsList = this.state.pricingDetails;
        if (pricingDetailsList) {
            pricingDetailsList?.forEach((details) => {
                details.isDefault = false;
            })
            pricingDetailsList[index].isDefault = true;

            this.setState({ pricingDetails: pricingDetailsList })
        }
        this.setState({ selectedPricingDetails: pricingDetails });
    }
    makeApiCallToValidate = (event: any) => {
        const value = event.target.value;
        const name = event.target.name;

        if (value?.includes(this.state.staticDeviceName)) {
            this.setState({ deviceName: value?.toUpperCase() }, () => {
                if (this.state.deviceName) {
                    const name = this.state.deviceName.replace('-', '');
                    this.props.getDevicesByRangeSearch('name', name).then(response => {
                        if (name === response?.data[0]?.name) {
                            this.setState({ deviceId: response.data[0].deviceId })
                            this.setState({ name: name, isValid: true });
                        } else {
                            this.setState({ isValid: false });
                        }

                    })
                }
            })

        }


    }
    public render() {
        this.validator.purgeFields();

        const { classes, history, deviceAvailableList, editDeviceDetail } = this.props;
        const { activeStep, formSteps, openModel, pricingDetails, paymentSuccess,activeStepName ,isEditDeviceRecord} = this.state;
        const { nickName, deviceId, loading, open, deviceName, name, availabilityOptions } = this.state;
        const { address, address2, city, state, postalCode, activationDate, renewDate, isValid } = this.state;
        const { recipientList, showDeviceError, pageNo, rowsPerPage, communicationInterval, communicationIntervalOption, sensitivity } = this.state;
        const { email, firstName, lastName, mobileNumber,
            landlineNumber, emailNotification, phoneNotification, textNotification } = this.state.recipientDetails;

        const getStepContent = (step: string, index: number) => {
            switch (step) {
                case 'Device Information':
                    return (    
                        <Grid container={true} spacing={1}>
                            <Grid item={true} xs={12} sm={12} className={classes.deviceInfoContainer}>
                                <Grid item={true} xs={12} sm={4} md={4}  spacing={1} style={{ alignSelf: 'center'}}>
                                    <FormControl fullWidth={true} className={classes.field} style={{alignItems: 'center'}}>
                                        <img alt="icon-logo" src="/images/wirelessfence.png" style={{margin:5}} width="25%" height="25%" />
                                    </FormControl>
                                </Grid>
                                <Grid container={true} spacing={3} >

                                    <Grid item={true} xs={12} sm={6} md={6}>
                                        <FormControl fullWidth={true} className={classes.field}>
                                            <InputLabel required={true} htmlFor="deviceName">Device Name (135EXXXXX) </InputLabel>
                                            {/* <img alt="icon-logo" src="/images/wirelessfence.png" width="25%" height="25%"/> */}
                                            <Input value={deviceName} onChange={(event) => this.makeApiCallToValidate(event)}
                                                placeholder="135E-" id="deviceName" name="deviceName" />
                                            {this.validators[index].validator.message('deviceName', deviceName, 'required')}
                                            {!isValid && <span className='srv-validation-message'>Please enter the valid Device Name</span>}
                                        </FormControl>
                                    </Grid>
                                    {deviceId &&
                                        <Grid item={true} xs={12} sm={6} md={6}>
                                            <FormControl fullWidth={true} className={classes.field}>
                                                <InputLabel required={true} htmlFor="nickName">Nick Name</InputLabel>
                                                <Input value={nickName || ''} onChange={this.onhandleChangeForm} id="nickName" name="nickName" />
                                                {this.validators[index].validator.message('nickName', nickName, 'required')}
                                            </FormControl>
                                        </Grid>}
                                    <Grid item={true} xs={12} sm={6} md={6}>
                                        <FormControl required={true} fullWidth={true} className={classes.field}>
                                            <InputLabel htmlFor="sensitivity">Alert Sensitivity</InputLabel>
                                            <Input value={sensitivity || ''}
                                                onChange={this.onhandleChangeForm}
                                                id="sensitivity" name="sensitivity" placeholder='1 (Low) to 10 (High) '
                                                onBlur={() => { this.validator.showMessageFor('Alert Sensitivity'); }}
                                            />
                                            {this.validators[index].validator.message('Alert Sensitivity', sensitivity, 'required|numeric')}
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6} md={6}>
                                        <FormControl variant="standard" fullWidth={true}>
                                            <InputLabel id="communicationInterval">Report Frequency</InputLabel>
                                            <Select
                                                labelId="communicationInterval"
                                                inputProps={{
                                                    name: "communicationInterval",
                                                    id: "communicationInterval"
                                                }}
                                                value={communicationInterval || ''}
                                                label="Report Frequency"
                                                onChange={this.onhandleChangeForm}
                                            >
                                                {availabilityOptions && availabilityOptions.map((options) => (
                                                    <MenuItem
                                                        key={options.label}
                                                        value={options.value}

                                                    >
                                                        {(options.value / 60) + 'hr'}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    );

                case 'Device Address':
                    return (<Grid item={true} xs={12} sm={12}>
                        <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12} sm={6}>
                                <CustomGoogleAutocomplete
                                    id="address"
                                    isRequired={true}
                                    name="address"
                                    value={address || ''}
                                    placeholder="Address Line 1"
                                    onBlur={() => this.validators[index].validator.showMessageFor('address')}
                                    onChange={this.onhandleChangeForm}
                                    onPlaceSelected={(place) => this.fillInAddress(place)} />
                                {this.validators[index].validator.message('address', address, 'required')}
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="address2">Address2</InputLabel>
                                    <Input value={address2 || ''} onChange={this.onhandleChangeForm} id="address2" name="address2" />
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="city">City</InputLabel>
                                    <Input value={city || ''} onChange={this.onhandleChangeForm} id="city" name="city" onBlur={() => this.validators[index].validator.showMessageFor('City')} />
                                    {this.validators[index].validator.message('City', city, 'required')}
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="state">State</InputLabel>
                                    <Input value={state || ''} onChange={this.onhandleChangeForm} id="state" name="state" onBlur={() => this.validators[index].validator.showMessageFor('State')} />
                                    {this.validators[index].validator.message('State', state, 'required')}
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="postalCode">Zip Code</InputLabel>
                                    <Input value={postalCode || ''} onChange={this.onhandleChangeForm} id="postalCode" name="postalCode" onBlur={() => this.validators[index].validator.showMessageFor('Zip Code')} />
                                    {this.validators[index].validator.message('Zip Code', postalCode, 'required|integer|min:0|max:5')}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>);
                case 'Subscription':
                    return (<Grid item={true} xs={12} sm={12}>
                        <Grid container={true} spacing={4}>
                            <Grid item={true} xs={12} sm={12}>
                                <Typography component={'div'} variant="h6" align='center'>
                                    <span style={{ fontWeight: 'bolder' }}>Note:</span> Do not refresh or reload  the page while doing the payment.
                                </Typography>
                            </Grid>
                            <Grid item={true}  xs={12} sm={12} md={12} className={classes.priceContainer}>
                                {pricingDetails && pricingDetails.map((details, stepIndex) => {
                                    return <PriceDetailCard planDetails={details}
                                        onSelectPayment={() => this.handleSelectPricingDetails(details, stepIndex)} />
                                })}

                            </Grid>
                            <Grid item={true} xs={12} sm={12} style={{padding: '5%',display:'flex',justifyContent:'center'}} >
                                {!paymentSuccess &&
                                <Grid item={true} xs={12} sm={12} md={6}style={{  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', padding:'2%',borderRadius:'10%'
                                }}> 
                                <Typography component={'div'} variant="h6" align='center'>
                                    <span style={{color:'#1976d2'}}>Make Payment</span> 
                                </Typography>                                    
                                <CardDetailForm handleSubmitFunc={this.handleSubmitPayment} />
                                </Grid>
                                }
                            </Grid>
                            

                            {/* <iframe src="https://paymentiframe.com/tag.cgi?u=sk_test_51Lc9bFHWpNEBMdEy0Gf0lEP5J465uNWUFcAJITLCEPC7zrfh6blAvt39SO9N6neyLrn1SOR9ROSPsdeJXpKkpuqg000BvB8zer&k=pk_test_51Lc9bFHWpNEBMdEyOJacqXeuHQYhhfdOwuZQkS5943Y9ragjUcTYw6ZS4b6bnL2IVnX1LoIAUJX09KDWxElTj0gq00gwK065hf" 
                                frameBorder='0' height='430px' width='450px' /> */}
                            {/* <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field} >
                                    <InputLabel htmlFor="activationDate" shrink={true} >Active Date</InputLabel>
                                    <Input value={activationDate || ''} type="date" onChange={this.onhandleChangeForm} onBlur={() => this.validators[index].validator.showMessageFor('Active Date')} id="activationDate" name="activationDate" />
                                    {this.validators[index].validator.message('Active Date', activationDate, 'required')}
                                </FormControl>
                            </Grid>
                            <Grid item={true} xs={12} sm={6}>
                                <FormControl required={true} fullWidth={true} className={classes.field}>
                                    <InputLabel htmlFor="renewDate" shrink={true}>Renew Date</InputLabel>
                                    <Input value={renewDate || ''} type="date" onChange={this.onhandleChangeForm} onBlur={() => this.validators[index].validator.showMessageFor('Renew Date')} id="renewDate" name="renewDate"
                                    />
                                    {this.validators[index].validator.message('Renew Date', renewDate, 'required')}
                                </FormControl>
                            </Grid> */}
                        </Grid>
                    </Grid>);
                case 'Recipients':
                    return (<Grid item={true} xs={12} sm={12}>
                        <Grid container={true}>
                            {/* {recipientList?.length > 0 &&  */}
                            <Grid item={true} xs={12} sm={12} style={{
                                alignItems: 'end',
                                justifyContent: 'end',
                                display: 'flex',
                                paddingBottom:5
                            }}>
                                <Button variant='contained' onClick={() => this.handleOpenModel()}>Add Recipient</Button>
                            </Grid>
                            {/* } 
                            {(Array.isArray(recipientList) && recipientList.length > 0) ? */}
                            <Grid item={true} xs={12} sm={12} md={12}>
                                <Typography component="div" className={classes.tableContainer}>
                                    <Table className={classes.table} >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.tableHeaderBg}>First Name</TableCell>
                                                <TableCell className={classes.tableHeaderBg}>Email</TableCell>
                                                <TableCell className={classes.tableHeaderBg}>Mobile Number</TableCell>
                                                <TableCell className={classes.tableHeaderBg}>Call Number</TableCell>
                                                <TableCell className={classes.tableHeaderBg}>Email Notification</TableCell>
                                                <TableCell className={classes.tableHeaderBg}>Text Notification</TableCell>
                                                <TableCell className={classes.tableHeaderBg}>Phone Notification</TableCell>
                                                <TableCell className={classes.tableHeaderBg}>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {<TableBody>
                                            {recipientList?.slice(pageNo * rowsPerPage, pageNo * rowsPerPage + rowsPerPage)?.map((availableRecipient: IRecipient, recipientIndex: number) => {
                                                return (
                                                    <TableRow key={`recipient${recipientIndex}`} onDoubleClick={() => this.editRecord(availableRecipient, recipientIndex)}>
                                                        <TableCell >{availableRecipient.firstName}</TableCell>
                                                        <TableCell >{availableRecipient.email} </TableCell>
                                                        <TableCell > <InputMask mask="+1 (999) 999-9999" style={{ border: 0 }} disabled={true} value={availableRecipient?.mobileNumber || ''} /> </TableCell>
                                                        <TableCell > <InputMask mask="+1 (999) 999-9999" style={{ border: 0 }} disabled={true} value={availableRecipient?.landlineNumber || ''} /> </TableCell>
                                                        <TableCell >{availableRecipient.emailNotification ? 'Enabled' : 'Disabled'} </TableCell>
                                                        <TableCell >{availableRecipient.textNotification ? 'Enabled' : 'Disabled'} </TableCell>
                                                        <TableCell >{availableRecipient.phoneNotification ? 'Enabled' : 'Disabled'} </TableCell>
                                                        <TableCell >
                                                            <EditIcon className={classes.iconPointer} onClick={() => this.editRecord(availableRecipient, recipientIndex)} />
                                                            <DeleteForeverIcon className={classes.iconPointer} onClick={() => this.deleteRecord(availableRecipient, recipientIndex)} />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}

                                        </TableBody>}
                                    </Table>
                                    {(Array.isArray(recipientList) && recipientList.length == 0) &&
                                    <Grid item={true} xs={12} sm={12}>
                                        <Card >
                                            <CardContent>
                                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                    No Recipient Found
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>}

                                </Typography>
                                <TablePagination
                                    component="div"
                                    count={recipientList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={pageNo}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeTableRowsPerPage}
                                />
                               
                            </Grid>
                            {/* : <Typography component={'div'} style={{ padding: 53 }} >No Recipients Found <br />
                                    <Button variant='contained' onClick={() => this.handleOpenModel()}>Add Recipient</Button>
                                </Typography>
                            } */}
                        </Grid>
                    </Grid >)
                default:
                    return '401 UNAUTHORIZED (or) CONTENT NOT AVAILABLE';
            }
        }

        const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
            [`&.${stepConnectorClasses.alternativeLabel}`]: {
             // top: 22,
            },
            [`&.${stepConnectorClasses.active}`]: {
              [`& .${stepConnectorClasses.line}`]: {
                backgroundImage: 'linear-gradient(95deg,rgb(123 204 238) 0%, rgb(55, 176, 227) 50%, rgb(25, 118, 210) 100%)',
              },
            },
            [`&.${stepConnectorClasses.completed}`]: {
              [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                  'linear-gradient( 95deg,rgb(123 204 238) 0%, rgb(55, 176, 227) 50%, rgb(25, 118, 210) 100%);',
              },
            },
            [`& .${stepConnectorClasses.line}`]: {
              height: 3,
              border: 0,
              backgroundColor: '#eaeaf0',
              borderRadius: 1,
            },
          }));


        return (<Paper className={classNames(classes.paper, classes.customer)} elevation={5} square={false}>

            <Container >
            <Typography component={'h1'} className={classes.formTitle} style={{ textAlign:'center' , fontWeight: 'bold',margin:'3%'}}>{isEditDeviceRecord?'Update Device':'Register Device'}</Typography>

                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                    {formSteps.map((label) => {
                        return (
                            <Step key={label}>
                                <StepLabel >{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
       
                <Box className={classes.stepper_container}>
                        <Grid item={true} xs={12} sm={12}>
                            <Typography component='div'>
                                <form autoComplete='off'>
                                    {getStepContent(activeStepName, activeStep)}
                                </form>
                            </Typography>
                        </Grid>

                    <Grid item={true} key={'KeyDevice' + activeStep} style={{paddingTop:'3%'}} >
                        <Grid container={true} className={classes.buttonContainer}>
                            {(activeStep !== 0) &&
                                <Grid item={true} xs={12} md={3} sm={12} margin={1}>
                                    <Button className={classes.buttonCommon} disabled={activeStep === 0} variant="outlined" onClick={this.handleBack}>Back</Button>
                                </Grid>
                            }
                            {activeStep === formSteps.length - 1 &&
                                <Grid item={true} xs={12} sm={12} md={3}  margin={1}>
                                    <Button variant="outlined" className={classes.buttonCommon} onClick={() => history.push('/devices')}
                                    >Back To Devices</Button>
                                </Grid>
                            }   
                            <Grid item={true} xs={12} sm={12} md={3}  margin={1}>
                                <Button
                                    className={classes.buttonCommon}
                                    variant="contained"
                                    disabled={((deviceName !== name) || (!deviceName && !name)) || (activeStep === 2 && !paymentSuccess)}
                                    // color="primary"
                                    onClick={activeStep === formSteps.length - 1 ? () => this.saveOrUpdateDeviceDetails(activeStep) : () => this.handleNext(activeStep)}
                                    style={{
                                        cursor: ((deviceName !== name) || (activeStep === 2 && !paymentSuccess)) ? 'not-allowed' : 'default'
                                    }}
                                >
                                    {activeStep === formSteps.length - 1 ? 'Save' : 'Next'}
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Box>
                <Dialog classes={{ paper: classes.dialogContainer }} onClose={() => this.handleCloseModel()} maxWidth="md" fullWidth={true} aria-labelledby="Items-View" open={openModel}>
                    <DialogTitle id="recipient-dialog-title" style={{ textAlign: "center", }}>Recipient</DialogTitle>
                    <DialogContent >
                        <Container maxWidth="lg" style={{ marginTop: 28 }}>
                            <form autoComplete='off'>
                                <Grid item={true} xs={12} sm={12}>
                                    <Grid container={true} spacing={3}>
                                        <Grid item={true} xs={12} sm={6} >
                                            <FormControl required={true} fullWidth={true} className={classes.field}>
                                                <InputLabel htmlFor="firstName">First Name</InputLabel>
                                                <Input value={firstName || ''} onChange={this.onhandleChangeRecipientForm} id="firstName" name="firstName"
                                                    onBlur={() => { this.validator.showMessageFor('First Name'); }}
                                                />
                                                {this.validator.message('First Name', firstName, 'required')}
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6} >
                                            <FormControl fullWidth={true} className={classes.field}>
                                                <InputLabel htmlFor="lastName">Last Name</InputLabel>
                                                <Input value={lastName || ''} onChange={this.onhandleChangeRecipientForm} id="lastName" name="lastName" />
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6} >
                                            <FormControl required={true} fullWidth={true} className={classes.field}>
                                                <InputLabel htmlFor="email">Email</InputLabel>
                                                <Input value={email || ''} onChange={this.onhandleChangeRecipientForm} id="email" name="email"
                                                    onBlur={() => this.validator.showMessageFor('Email')}
                                                />
                                                {this.validator.message('Email', email, 'required|email')}
                                            </FormControl>
                                        </Grid>

                                        <Grid item={true} xs={12} sm={6} >
                                            <FormControl required={true} fullWidth={true} className={classes.field}>
                                                <InputLabel htmlFor="mobileNumber">Text Number</InputLabel>
                                                <PhoneNumberMaskInput value={mobileNumber || ''}
                                                    onChange={this.onhandleChangeRecipientForm}
                                                    id="mobileNumber"
                                                    name="mobileNumber"
                                                    disabled={false}
                                                    onBlur={() => this.validator.showMessageFor('Text Number')}
                                                />
                                                {this.validator.message('Text Number', mobileNumber, 'required|phone')}
                                            </FormControl>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6} >
                                            <FormControl required={true} fullWidth={true} className={classes.field}>
                                                <InputLabel htmlFor="landlineNumber">Call Number</InputLabel>
                                                <PhoneNumberMaskInput value={landlineNumber || ''}
                                                    onChange={this.onhandleChangeRecipientForm}
                                                    id="landlineNumber"
                                                    disabled={false}
                                                    name="landlineNumber"
                                                    onBlur={() => this.validator.showMessageFor('Call Number')}
                                                />
                                                {this.validator.message('Call Number', landlineNumber, 'required|phone')}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item={true} xs={12} sm={12}>
                                    <Grid container={true} spacing={2} style={{ marginTop: 20 }}>
                                        <Grid item={true} xs={12} sm={12}>
                                            <Typography variant="subtitle1" align='left' component="h6" className={classes.formTitle}>Notification Preferences</Typography>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={12}>
                                            <Grid container={true} spacing={3}>
                                                <Grid item={true} xs={12} sm={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            checked={emailNotification ? emailNotification : false}
                                                            onChange={(event) => this.onhandleChangeFormCheckBox(event)}
                                                            name="emailNotification"
                                                            color="primary"
                                                        />
                                                        }
                                                        label="Send Email"
                                                    />
                                                </Grid>
                                                <Grid item={true} xs={12} sm={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            checked={phoneNotification ? phoneNotification : false}
                                                            onChange={(event) => this.onhandleChangeFormCheckBox(event)}
                                                            name="phoneNotification"
                                                            color="primary"
                                                        />
                                                        }
                                                        label="Call by Phone"
                                                    />
                                                </Grid>
                                                <Grid item={true} xs={12} sm={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            checked={textNotification ? textNotification : false}
                                                            onChange={(event) => this.onhandleChangeFormCheckBox(event)}
                                                            name="textNotification"
                                                            color="primary"
                                                        />
                                                        }
                                                        label="Send Text"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item={true} xs={12} sm={12}>
                                    <Grid container={true} spacing={2} style={{ flexDirection: 'row-reverse', marginTop: 5, marginBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                        <Grid item={true} xs={12} sm={6} >
                                            <Button
                                                onClick={this.handleSubmitRecipientDetails}
                                                variant="contained"
                                                className={classes.buttonCommon}>Proceed</Button>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6}  >
                                            <Button
                                                className={classes.buttonCommon}
                                                onClick={this.handleCloseModel}
                                                variant="outlined"  
                                            >Cancel</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </Container>

                    </DialogContent>
                </Dialog>
            </Container >

        </Paper>)
    }
}
const styles = (theme: Theme) => ({
    stepper_container:{
        padding: '5%',
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        margin: '2%'
        },
    paper: {
        padding: '1%',
        color: theme.palette.text.secondary,
        marginBottom: '6%',
        [theme.breakpoints.only('xs')]: {
            marginBottom: '30%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },

    },
    deviceInfoContainer:{
       display:'flex',
       [theme.breakpoints.down('sm')]: {
        flexDirection: 'column !important'
    },
    },
    buttonCommon: {
        ":disabled": {
            cursor: 'not-allowed'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        // [theme.breakpoints.between('md', 'xl')]: {
        //     width: '70%'
        // },
    },
    buttonContainer:{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        textAlign: 'center',
     
        [theme.breakpoints.only('md')]:{
            flexWrap: 'nowrap !important'
        },
        [theme.breakpoints.only('sm')]:{
            flexWrap: 'wrap !important'
        },
        [theme.breakpoints.only('xs')]:{
            flexWrap: 'wrap !important'
        }
    },
    iconPointer: {
        cursor: 'pointer'
    },
    formTitle: {
        fontWeight: 'bold'
    },
    alignButton: {
        marginBottom: '4px',
        [theme.breakpoints.between('md', 'xl')]: {
            flexDirection: 'row-reverse',
            textAlign: 'center',
        },
    },
    sectionTitle: {
        marginBottom: "20px"
    },
    tableContainer: {
        padding: '1px',
        overflow: 'auto'
    },
    dialogContainer: {
        width: '50%',
        minHeight: '500px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            margin: 0,
            maxWidth: '100%',
            maxHeight: 'none',
            borderRadius: 0,
        },
    },
    tableHeaderBg: {
        backgroundColor: '#c2e3f1',
        fontWeight: 700
    },
   priceContainer:{
    display:'flex',
    padding:0,
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column !important',
    },
   },
   
});
const mapStateToProps = (state: any) => {
    return {
        editDeviceDetail: state?.device?.editDeviceDetail,
        customers: state?.common?.allCustomersList,
        sensorDetails: state?.device?.sensorDetailsByDeviceId,
        getSelectedUsers: state?.unwiredUser?.userSelected,
        deviceAvailableList: state?.device?.deviceAvailableList,
        user: state?.auth?.user,
        pricingDetails: state.common?.pricingDetails
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getDeviceById: DeviceAction.getDeviceById,
        resetDeviceGrid: DeviceAction.resetDeviceGrid,
        resetEditDeviceDetails: DeviceAction.resetEditDeviceDetails,
        putUpdateDeviceDetailsByDeviceId: DeviceAction.putUpdateDeviceDetailsByDeviceId,
        postSaveDeviceDetails: DeviceAction.postSaveDeviceDetails,
        getDevicesByRangeSearch: DeviceAction.getDevicesBySearchText,
        getPricingDetails: CommonActions.getAllPricingDetails,
        postMakePayment: DeviceAction.postMakePayment
    }, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(DeviceForm as any)) as any);

