import { ActionType, IAppAction } from '../actions/Helpers';
const initialState = {
    isAppLoader: false,
    pricingDetails: [] as any
};
export const common = (state = initialState, action = {} as IAppAction) => {
    switch (action.type) {
        case ActionType.OPEN_SPINNER:
        case ActionType.SHOW_LOADER: {
            return {
                ...state,
                isAppLoader: true,
            };
        }
        case ActionType.CLOSE_SPINNER:
        case ActionType.HIDE_LOADER: {
            return {
                ...state,
                isAppLoader: false,
            };
        }
        case ActionType.GET_PRICING_DETAILS: {
            return {
                ...state,
                pricingDetails: action.payload,
            };
        }
        default:
            return state;
    }
};
