import { IAppAction, ActionType } from './Helpers';
import { AppState } from "../../state/AppState";
import { Dispatch } from "redux";
import * as ApiVariables from '../../service/ApiVariables';
export const getAllCustomers = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return new Promise((resolve, reject) => {
        ApiVariables.customers.getCustomersByRange.setLimit = 1000;
        api(ApiVariables.customers.getCustomersByRange).then((response: any) => {
            dispatch({ type: ActionType.SET_CUSTOMER_SELECT_BOX_VALUE, payload: response });
            resolve(response);
        }).catch((response: any) => {
            reject(response);
        })
    })
};

export const getAllRecipientsUsersAsOUorAU = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return new Promise((resolve, reject) => {
        ApiVariables.users.getUsersByRangeAsOwnerOrAU.setStart = 0;
        ApiVariables.users.getUsersByRangeAsOwnerOrAU.setLimit = 1000;
        api(ApiVariables.users.getUsersByRangeAsOwnerOrAU).then((response: any) => {
            dispatch({ type: ActionType.GET_RECIPIENTS_USERS, payload: response });
            resolve(response);
        }).catch((response: any) => {
            reject(response);
        })
    })
};

export const getAllRecipientsUsersAsPU = (customerId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return new Promise((resolve, reject) => {
        ApiVariables.users.getUsersByRangeAsPU.id = customerId;
        api(ApiVariables.users.getUsersByRangeAsPU).then((response: any) => {
            dispatch({ type: ActionType.GET_RECIPIENTS_USERS, payload: response });
            resolve(response);
        }).catch((response: any) => {
            reject(response);
        })
    })
};

export const getAllCustomersByOwnerId = (ownerId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return new Promise((resolve, reject) => {
        ApiVariables.customers.getCustomersByOwnerId.id = ownerId;
        ApiVariables.customers.getCustomersByOwnerId.setLimit = 1000;
        api(ApiVariables.customers.getCustomersByOwnerId).then((response: any) => {
            dispatch({ type: ActionType.SET_CUSTOMER_SELECT_BOX_VALUE, payload: response });
            resolve(response);
        }).catch((response: any) => {
            reject(response);
        })
    })
};


export const getAllPricingDetails = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    return new Promise((resolve, reject) => {
        api(ApiVariables.payment.getPricingDetails).then((response: any) => {
            dispatch({ type: ActionType.GET_PRICING_DETAILS, payload: response.data });
            resolve(response);
        }).catch((response: any) => {
            reject(response);
        })
    })
};