import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker, InfoWindow, Circle } from 'google-maps-react';
interface IPageProps {
    google?: any;
    center: ILatAndLng;
    markersOptions: any[];
    zoom: number;
    containerStyle: any;
    flightPositions?: ILatAndLng[]
    address: string;
    centerName: string
    mapViewFrom?: string;
}
interface ILatAndLng {
    lat: number;
    lng: number;
}
class MapComponent extends Component<IPageProps, any>{

    constructor(props: any) {
        super(props);
        this.state = {
            activeMarker: {},
            selectedPlace: {},
            showingInfoWindow: false
        };
    }


    private onMarkerClick = (props: any, marker: any) =>
        this.setState({
            activeMarker: marker,
            selectedPlace: props,
            showingInfoWindow: true
        });

    private onInfoWindowClose = () =>
        this.setState({
            activeMarker: null,
            showingInfoWindow: false
        });

    private onMapClicked = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                activeMarker: null,
                showingInfoWindow: false
            });
        }

    };

    public render() {
        const { markersOptions, flightPositions, center, zoom, containerStyle, address, mapViewFrom } = this.props;
        return (
            <Map
                google={this.props.google}
                onClick={this.onMapClicked}
                initialCenter={center ? (center.lat !== null && center.lat !== 0) ? center : {
                    lat: 41.676388,
                    lng: -86.250275
                } : {
                    lat: 41.676388,
                    lng: -86.250275
                }
                }
                center={{
                    lat: (center.lat !== null && center.lat !== 0) ? center.lat : 41.676388,
                    lng: (center.lng !== null && center.lng !== 0) ? center.lng : -86.250275
                }
                }
                /** @ts-ignore */
                //flightPlanCoordinates={flightPositions.length > 0 ? flightPositions : []}
                //zoom={center ? (center.lat !== null && center.lat !== 0) ? zoom : 3 : 3}
                containerStyle={containerStyle}
            >

                {(Array.isArray(markersOptions) && markersOptions.length > 0) ? markersOptions.map((marker: any, markerIndex: number) => {
                    if (((marker.latitude && marker.longitude) && (marker.latitude !== "" && marker.longitude !== ""))) {
                        return (
                            <Marker key={`marker${markerIndex}`}
                                /** @ts-ignore */
                                label={''} // (mapViewFrom && mapViewFrom === "customerView") ? marker.name : markerIndex.toString()
                                /** @ts-ignore */
                                name={(mapViewFrom && mapViewFrom === "customerView") ? marker.name : markerIndex.toString()}
                                onClick={this.onMarkerClick}
                                position={{ lat: marker.latitude, lng: marker.longitude }}
                            />)
                    }
                    return (null)
                }) : (center.lat !== null && center.lat !== 0) && <Marker
                    /** @ts-ignore */
                    name={address ? address : ''}
                    label={''} // (mapViewFrom && mapViewFrom === "customerView") ? centerName ? centerName : 'H' :
                    onClick={this.onMarkerClick}
                    position={{ lat: center.lat, lng: center.lng }}
                />}
                <Circle center={{ lat: center.lat, lng: center.lng }} radius={2000}  strokeColor= '#ff0000'></Circle>
                <InfoWindow
                    marker={this.state.activeMarker}
                    /** @ts-ignore */
                    onClose={this.onInfoWindowClose}
                    visible={this.state.showingInfoWindow}
                >
                    <div>
                        <h4>{
                            /** @ts-ignore */
                            this.state.selectedPlace.name
                        }</h4>
                    </div>
                </InfoWindow>
            </Map>
        );
    }
}
const Container = GoogleApiWrapper({ apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY ? process.env.REACT_APP_GOOGLE_MAP_KEY : '', version: "3.38", region: 'US' })((MapComponent as any) as React.ComponentType<any>);
export default Container;