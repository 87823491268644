import { Button, Grid, Paper, Theme, Typography, Dialog } from '@mui/material';
import { withStyles } from '@mui/styles';
import * as React from 'react';
import Geocode from "react-geocode";
import { geocodeByAddress } from 'react-places-autocomplete';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import SimpleReactValidator from 'simple-react-validator';
import DeviceDetailsCard from '../../components/card/DeviceDetailsCard';
import { SearchBox } from '../../components/search/SearchBox';
import IFilterMenuOptionsModel from '../../models/common/IFilterMenuOptionsModel';
import IFilterModel from '../../models/common/IFilterModel';
import IDevice from '../../models/device/IDeviceView';
import * as DeviceActions from '../../store/actions/Devices.Action';
import { exportDevicesData } from '../../store/actions/Devices.Action';
import * as SensorReadingsActions from '../../store/actions/SensorReadings.Action';
import { DialogTitle, DialogContent } from '../../components/dialog/DialogTitle';
import MapComponent from '../../components/map/MapComponent';
import DeviceDetailCard from '../../components/card/DeviceDetailCard';

const classNames = require('classnames');

interface IPageProps {
    devices?: IDevice[],
    classes: any,
    getDevicesByRange: (start: number, limit: number, paramName?: string, paramValue?: string) => void;
    totalResults?: number;
    editDeviceDetail?: (deviceDetail: IDevice) => void,
    resetDeviceGrid: () => void;
    history: any;
    utility: any;
    materialChartData: any[];
    google: any;
    getLocations: (locationId: number) => void;
    getLocationsByDeviceId: (deviceId: number) => any;
    match: any;
    location: ILocation;
    postSaveETAAddress: (body: string) => any;
    isAppLoader: boolean
}

interface IPageState {
    deviceTablePage: number,
    deviceTableRowsPerPage: number,
    pageStartCount: number,
    anchorEl: any;
    selectedFilterMenuIndex: number,
    filterMenuOptions: IFilterMenuOptionsModel[],
    selectedFilterMenu: string | number | boolean;
    selectedFilterMenuKey: string;
    searchBoxValue: string;
    searchBoxFilterKey: string,
    openMapModel: boolean;
    batteryValue: number;
    temperatureValue: any;
    center: ILatAndLng;
    zoom: number;
    flightPlanCoordinates: ILatAndLng[];
    address: string;
    name: string;
    markers: any[]
    exportAnchorEl: any;
    openETAModel: boolean;
    addressETA: string;
    address1?: string;
    address2?: string;
    city?: string;
    zip?: string;
    state?: string;
    etaDateTime: any,
    openETADeviceId?: string
    nickName?: string;
}
interface ILatAndLng {
    lat: number | any;
    lng: number | any;
}
interface ILocationState {
    customerName: string
    isActive?: string
}

export interface ILocation<S = ILocationState> {
    pathname: string;
    search: string;
    state: S;
    hash: string;
    key?: string;
}

class Devices extends React.Component<IPageProps, IPageState> {
    public validator: SimpleReactValidator = new SimpleReactValidator();
    constructor(props: IPageProps) {
        super(props);
        this.state = {
            deviceTablePage: 0,
            deviceTableRowsPerPage: 10,
            pageStartCount: 10,
            anchorEl: null,
            selectedFilterMenuIndex: 0,
            filterMenuOptions: [{ "label": 'Device Name', "key": 'name' },
            { "label": 'IMEI', "key": 'imei' },
            { "label": 'Customer Name', "key": 'customerName' }],
            selectedFilterMenu: 'Device Name',
            selectedFilterMenuKey: 'name',
            searchBoxValue: '',
            searchBoxFilterKey: '',
            openMapModel: false,
            batteryValue: 0,
            temperatureValue: 0,
            center: {
                lat: null,
                lng: null
            },
            zoom: 15,
            flightPlanCoordinates: [],
            address: '',
            name: '',
            markers: [],
            exportAnchorEl: null,
            openETAModel: false,
            addressETA: '',
            etaDateTime: new Date(),
        }
    }

    public async componentWillMount() {
        const { customerName, isActive } = (this.props.location && this.props.location.state) || {};
        if (customerName) {
            this.props.getDevicesByRange(0, 10, 'customerName', customerName);
        } else if (isActive) {
            this.props.getDevicesByRange(0, 10, 'isActive', isActive);
        } else {
            this.props.getDevicesByRange(0, 10);
        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this, messages: {
                integer: "Please enter only number"
            }
        });
    }
    public componentDidMount() {
        Geocode.setApiKey(process.env?.REACT_APP_GOOGLE_MAP_KEY);
        const { customerName } = (this.props.location && this.props.location.state) || {};
        if (customerName) {
            this.setState({
                searchBoxValue: customerName,
                selectedFilterMenu: 'Customer Name',
                selectedFilterMenuKey: 'customerName',
                searchBoxFilterKey: 'customerName',
                selectedFilterMenuIndex: 1
            })
        }
    }


    private handleChangeDevicePage = (event: any, page: number) => {
        console.log('page', page)
    };

    private handleChangeTableRowsPerPage = async (event: any) => {
        this.props.getDevicesByRange(0, event.target.value as number, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
        this.setState({ deviceTableRowsPerPage: event.target.value, pageStartCount: event.target.value, deviceTablePage: 0 });
    };

    private loadNextPage = async () => {
        this.props.getDevicesByRange(this.state.pageStartCount, this.state.deviceTableRowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
        this.setState({ deviceTablePage: this.state.deviceTablePage + 1, pageStartCount: this.state.pageStartCount + this.state.deviceTableRowsPerPage });
    }

    private loadPreviousPage = () => {
        this.setState({ deviceTablePage: this.state.deviceTablePage - 1, pageStartCount: this.state.pageStartCount - this.state.deviceTableRowsPerPage })
        if ((this.state.deviceTablePage - 1) === 0) {
            this.props.resetDeviceGrid();
            this.props.getDevicesByRange(0, this.state.deviceTablePage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
        }
    }

    private editDeviceRecord = (device: IDevice) => {
        this.props.history.push(`/edit-device-details/${device.deviceId}`)
    }

    private readingDeviceSensorDetailsByImei = (imei: any) => {
        this.props.history.push({
            pathname: '/sensor-readings',
            state: { 'imei': imei }
        })
    }
    private openMapWindow = async (device: any) => {
        if (device) {
            const { flightPlanCoordinates } = this.state;
            const address = device.address + (device.address ? ' ' : '') + device.address2 + (device.address ? ', ' : '') + device.city + (device.city ? ', ' : '') + device.state + (device.state ? ', ' : '') + device.postalCode;
            let deviceMarkers: any[] = [];
            let latOffSet = 0;
            let lonOffSet = 0;
            if (device.latitudeOffset) {
                latOffSet = +device.latitudeOffset;
            }
            if (device.longitudeOffset) {
                lonOffSet = +device.longitudeOffset;
            }
            let position: ILatAndLng = {
                lat: undefined,
                lng: undefined
            };
            if (device.latitude && device.longitude) {
                position = { lat: (+device.latitude + latOffSet), lng: (+device.longitude + lonOffSet) };
            }
            // flightPlanCoordinates.push(position);
            await this.props.getLocationsByDeviceId(device.deviceId).then(async (response: any) => {
                if (response && response.data) {
                    await response.data.forEach((marker: any, markerIndex: number) => {
                        const markMakeReadable = { ...marker };
                        if (markMakeReadable.latitude && markMakeReadable.longitude) {
                            markMakeReadable.latitude = (+markMakeReadable.latitude + latOffSet);
                            markMakeReadable.longitude = (+markMakeReadable.longitude + lonOffSet);
                        }
                        deviceMarkers.push(markMakeReadable);
                        if (markerIndex === 0) {
                            this.setState({ center: { lat: (+marker.latitude + latOffSet), lng: (+marker.longitude + lonOffSet) } })
                        }
                    })
                    this.setState({ markers: deviceMarkers })
                } else {
                    this.toGetTheCenterLocationPoint(position, address, deviceMarkers)
                }
            }).catch((response: any) => {
                console.error(response);
                this.toGetTheCenterLocationPoint(position, address, deviceMarkers)
            })
            const name = device.name;
            const nickName = device.nickName;
            if (deviceMarkers.length === 0) {
                this.toGetTheCenterLocationPoint(position, address, deviceMarkers)
            }

            this.setState({ flightPlanCoordinates, address, name })
            this.setState({ nickName })
            this.setState({ openMapModel: true });


        }
    }

    private toGetTheCenterLocationPoint = async (position: ILatAndLng, address: string, markers: any) => {
        if ((Array.isArray(markers) && markers.length === 0) && (!position.lat && !position.lng && address !== "")) {
            await Geocode.fromAddress(address).then((response: any) => {
                const { lat, lng } = response.results[0].geometry.location;
                if (position.lat && position.lng) {
                    this.setState({ center: { lat: response.results[0].geometry.location.lat, lng: response.results[0].geometry.location.lng } })
                } else {
                    position.lat = lat;
                    position.lng = lng;
                    this.setState({ center: { lat: response.results[0].geometry.location.lat, lng: response.results[0].geometry.location.lng } })
                }
            }, error => {
                console.error(error);
            });
        }


    }
    private handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    }
    private handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, option: IFilterModel) => {
        this.setState({ selectedFilterMenuIndex: index, anchorEl: null, selectedFilterMenu: option.label, selectedFilterMenuKey: option.key, searchBoxFilterKey: option.key })
    };
    private handleClose = () => {
        this.setState({ anchorEl: null, });
    }
    private handleSearchBox = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }
    private onEnterKeyPress = (event: any) => {
        if (event.keyCode === 13 || event.keyCode === '13' || event.key === 'Enter') {
            this.callSearchByParam();
        }
    }
    private callSearchByParam = async () => {
        this.props.getDevicesByRange(0, this.state.deviceTableRowsPerPage, this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey, this.state.searchBoxValue);
    }
    private exportDevicesData = () => {
        exportDevicesData();
        this.setState({ exportAnchorEl: null, });
    }

    private handleCloseExportMenu = () => {
        this.setState({ exportAnchorEl: null, });
    }
    private handleClickExport = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ exportAnchorEl: event.currentTarget })
    }
    private handleSubmitEATAddressDetails = async (event: any) => {
        event.preventDefault();
        if (this.validator.allValid()) {
            const { address1, etaDateTime, openETADeviceId, address2, city, state, zip } = this.state;
            const payload = {
                "etaDateTime": etaDateTime,
                "address1": address1,
                "address2": address2,
                "city": city,
                "deviceId": openETADeviceId,
                "state": state,
                "zip_code": zip,
            }
        } else {
            this.validator.showMessages();
        }
    }

    private handleDateChange = (date: Date) => {
        this.setState({ etaDateTime: date })
    }
    private handleChange = (address: string) => {
        this.setState({ address1: address });
    };
    private handleSelect = async (address: string) => {
        await geocodeByAddress(address).then(results => {
            let addressG = results[0].formatted_address;
            let pin = '';
            let stateG = '';
            let cityG = '';
            let streetNumber = '';
            let route = '';
            results[0].address_components?.forEach((gComponent, index) => {
                switch (gComponent.types[0]) {
                    case 'formatted_address':
                        addressG = gComponent.long_name;
                        break;
                    case 'street_number':
                        streetNumber = gComponent.long_name;
                        break;
                    case 'route':
                        route = gComponent.short_name;
                        break;
                    case 'locality':
                        cityG = gComponent.long_name;
                        break;
                    case 'administrative_area_level_1':
                        stateG = gComponent.long_name;
                        break;
                    case 'postal_code':
                        pin = gComponent.long_name;
                        break;
                    default:
                        break;
                }

            })
            const values = streetNumber + addressG + route;
            this.setState({
                address1: values,
                state: stateG,
                city: cityG,
                zip: pin,
            })
        }).catch(error => console.error('Error', error));
    };

    private handleCloseSensorModel = () => {
        this.setState({
            openMapModel: false, markers: [], batteryValue: 0, temperatureValue: 0,
            address: '', flightPlanCoordinates: [], center: { lat: null, lng: null }
        })
    }

    public render(): JSX.Element {
        this.validator.purgeFields();
        const { devices, classes, history, isAppLoader } = this.props; // utility
        const { selectedFilterMenuIndex, anchorEl, openMapModel, selectedFilterMenu, filterMenuOptions,
            searchBoxValue, zoom, center, address, flightPlanCoordinates, name, nickName } = this.state;

        return (<Paper className={classNames(classes.paper, classes.device, 'container')} elevation={5} square={false}>
            <Grid container={true} style={{ marginTop: 5 }} >
                <Grid item={true} xs={12} sm={10}>
                    <SearchBox handleClickListItem={this.handleClickListItem} anchorEl={anchorEl}
                        handleClose={this.handleClose} filterMenuOptions={null} selectedFilterMenuIndex={selectedFilterMenuIndex}
                        handleMenuItemClick={this.handleMenuItemClick} selectedFilterMenu={selectedFilterMenu} searchBoxValue={searchBoxValue}
                        handleSearchBox={this.handleSearchBox} onEnterKeyPress={this.onEnterKeyPress} gridLabel={'Devices'} />
                </Grid>
                {Array.isArray(devices) && (devices.length > 0 && devices.length <= 3) && <Grid item={true} xs={12} sm={2}>
                    <Typography component={'div'} style={{ display: 'flex', justifyContent: 'end', paddingRight: 8, paddingTop: 8 }}>
                        <Button variant="contained" size="large" onClick={() => history.push('/device-register')}>
                            Register Device
                        </Button>
                    </Typography>
                </Grid>}
            </Grid>
            <hr style={{ color: 'rgb(224 224 224)', borderBottom: '1px solid' }} />
            <Grid className={classes.mainContainer} container={true}  style={{ padding: '2%', margin: '0%' }}>
                {(Array.isArray(devices) && devices.length > 0) && devices.map((deviceDetails: IDevice, index: number) => {
                    return (
                        <Grid item={true} lg={3} xl={3} md={4} sm={6} xs={12} key={'KeyDevice' + index}  className={classes.gridItem} >
                            <Typography component={'div'}>
                                {/* <DeviceDetailsCard deviceDetail={deviceDetails} onClick={() => this.editDeviceRecord(deviceDetails)} openMapWindow={() => this.openMapWindow(deviceDetails)} /> */}
                                <DeviceDetailCard deviceDetail={deviceDetails} onEdit={() => this.editDeviceRecord(deviceDetails)} openMapWindow={() => this.openMapWindow(deviceDetails)} />
                            </Typography>
                        </Grid>
                    );
                })}
                {
                    (!isAppLoader && Array.isArray(devices) && devices.length <= 0) &&
                    <Grid item={true} xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" size="large" style={{ minHeight: '60px' }} onClick={() => history.push('/device-register')}>
                            Register Device
                        </Button>
                    </Grid>
                }
            </Grid>
       
            <Dialog classes={{ paper: classes.dialogContainer }} onClose={() => this.handleCloseSensorModel()}
                maxWidth="md" fullWidth={true} aria-labelledby="Items-View" open={openMapModel}>
                <DialogTitle id="customized-dialog-title" onClose={() => this.handleCloseSensorModel()}
                    style={{ textAlign: 'center' }}>Device: {nickName}</DialogTitle>
                <DialogContent >
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} sm={12}>
                            <MapComponent
                                markersOptions={this.state.markers}
                                zoom={zoom}
                                address={address}
                                centerName={name}
                                flightPositions={flightPlanCoordinates}
                                center={center}
                                containerStyle={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '55vh'
                                }} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Paper>)
    }
}
const styles = (theme: Theme) => ({
    mainContainer: {
        justifyContent: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(./images/nyc-contour.png)`,
    },
    paper: {
        color: theme.palette.text.secondary,
        border: `1px solid ${theme.palette.divider}`,
        //minHeight: 500,
        paddingBottom: 0,

    },
    device: {
        overflow: 'hidden',

    },
    textAlign: {
        textAlign: 'center',
    },
    dialogContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            margin: 0,
            maxWidth: '100%',
            maxHeight: 'none',
            borderRadius: 0,
        },
    },
    gridItem:{
        padding:'1%',
        display: 'flex',
        justifyContent: 'center'
      // maxWidth:'25%'     
    }
});
const mapStateToProps = (state: any) => {
    return {
        devices: state?.device?.devices,
        totalResults: state?.device?.totalResults,
        utility: state?.utility,
        isAppLoader: state?.common?.isAppLoader
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getDevicesByRange: DeviceActions.getDevicesByRange,
        editDeviceDetail: DeviceActions.editDeviceDetails,
        getLocations: SensorReadingsActions.getAllLocationMarkers,
        resetDeviceGrid: DeviceActions.resetDeviceGrid,
        getLocationsByDeviceId: DeviceActions.getLocationsByDeviceId,
        postSaveETAAddress: DeviceActions.postETAAddress,
    }, dispatch);
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(Devices as any)) as any);
